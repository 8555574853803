var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingData,
          expression: "loadingData"
        }
      ],
      staticClass: "el-loading--maia",
      attrs: { id: "reply", "element-loading-text": "回复数据获取中..." }
    },
    [
      _c("div", { staticClass: "list" }, [
        _vm.commentList.length > 0
          ? _c(
              "ul",
              _vm._l(_vm.commentList, function(item) {
                return _c("li", { key: item.cmntID }, [
                  _c("div", { staticClass: "photo" }, [
                    _c(
                      "div",
                      {
                        staticClass: "imgDiv",
                        staticStyle: { display: "none" },
                        attrs: { id: "imgDiv" + item.cmntID }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.createUserName.substring(
                                item.createUserName.length - 1
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c("img", {
                      staticClass: "commentImg",
                      attrs: {
                        src:
                          "https://img.longfor.com/headImg/small/" +
                          item.userName +
                          ".jpg"
                      },
                      on: {
                        error: function($event) {
                          return _vm.avatarError(item, $event)
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", [_vm._v(_vm._s(item.createUserName))]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(item.createDate))])
                    ]),
                    !!item.urlList && item.urlList.length > 0
                      ? _c(
                          "div",
                          { staticClass: "image" },
                          [
                            _c(
                              "viewer",
                              {
                                staticClass: "uploadImage",
                                staticStyle: { height: "100%" },
                                attrs: { images: item.urlList }
                              },
                              _vm._l(item.urlList, function(img) {
                                return _c("img", {
                                  key: img,
                                  attrs: { src: img }
                                })
                              }),
                              0
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "data" }, [
                      _vm._v(" " + _vm._s(item.content) + " ")
                    ]),
                    _c("div", { staticClass: "line" })
                  ])
                ])
              }),
              0
            )
          : _c("div", { staticClass: "empty" }, [
              _c("img", {
                attrs: { src: require("./../image/Empty.png"), alt: "" }
              }),
              _c("p", [_vm._v("还没有人发布消息哦")])
            ])
      ]),
      _vm.isNewList &&
      !(_vm.itemData.isDel == "1" || _vm.itemData.fileIsDel == "0")
        ? _c("div", { staticClass: "operReply" }, [
            _c("div", {
              ref: "editBox",
              staticClass: "edit-box",
              attrs: {
                id: "editBox",
                contenteditable: "true",
                placeholder: "请填写问题回复，截图可 Ctrl+V 粘贴至此处..."
              },
              on: {
                paste: _vm.handleImagePaste,
                input: _vm.handleInputChange,
                click: _vm.handleInputClick
              }
            }),
            _c(
              "div",
              { staticClass: "oper" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "maiaPrimary", size: "small" },
                    on: { click: _vm.replyComments }
                  },
                  [_vm._v("评论")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.userListShowFlag,
              expression: "userListShowFlag"
            }
          ],
          staticClass: "user-list",
          attrs: { id: "show" }
        },
        [
          _c("div", { staticClass: "header" }, [_vm._v("人员列表")]),
          _c(
            "div",
            { staticClass: "cont" },
            [
              _vm.userList.length == 0
                ? _c("div", { staticClass: "no-match" }, [
                    _vm._v(_vm._s(_vm.messageTip))
                  ])
                : _vm._e(),
              _vm._l(_vm.userList, function(item) {
                return _c(
                  "div",
                  {
                    key: item.userID,
                    staticClass: "user-item",
                    on: {
                      click: function($event) {
                        return _vm.checkUser(
                          item.name,
                          item.userId,
                          item.userName
                        )
                      }
                    }
                  },
                  [_c("p", [_vm._v(_vm._s(item.name))])]
                )
              })
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }