var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.recordLoading,
          expression: "recordLoading"
        }
      ],
      attrs: { id: "record" }
    },
    [
      _vm.timeData.length > 0
        ? _c(
            "ul",
            _vm._l(_vm.timeData, function(item, index) {
              return _c("li", { key: index }, [
                _c("div", { staticClass: "title" }, [
                  _c("span", {
                    staticClass: "circle",
                    class: { btnColor: index === 0 }
                  }),
                  _c("span", [_vm._v(_vm._s(item.operDate))]),
                  _c("span", { staticClass: "status" }, [
                    _vm._v(_vm._s(item.queStatusNew))
                  ])
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "line" }, [
                    _c("div", { staticClass: "gray" }, [
                      _c("p", [
                        _c("span", [_vm._v("操作人：")]),
                        _vm._v(_vm._s(item.operUserName) + " ")
                      ]),
                      _c("p", [
                        _c("span", [_vm._v("说明：")]),
                        _vm._v(_vm._s(item.content) + " ")
                      ])
                    ])
                  ])
                ])
              ])
            }),
            0
          )
        : _c("div", { staticClass: "empty" }, [
            _c("img", {
              attrs: { src: require("./../image/Empty.png"), alt: "" }
            }),
            _c("p", [_vm._v("还没有记录哦")])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }